@import url(https://fonts.googleapis.com/css2?family=Jura:wght@400;600;700&family=Montserrat&display=swap);
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  height: 100%;
}

button:focus { outline:none }

/* Google Fonts */


.home-button-enter {
  opacity: 0;
}
.home-button-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.carousel{
  cursor:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHZSURBVHgBnVQ9T8JQFD1V46DLG3T1Y9PJxt2IC2EzcdbA5kj9BZTFUcrIRoIrhFHd0NWYurkBjmJCB2DSPO9ptWlLJYGzPN69vYd3z/0wkIICtPoCivIzYwCmBpScnpyunO4yUK3D6CXjjCTJN1BaU7CyQrWfAbZNQO6YeEDfBZ7qwFsH+OzBEdKykHppr9m5gO42ilqPh3omBl2ta3mt+T3jptKio1XSc6FZCglVSCaGSq2gF0LD8gkrYXpXO8HToxhJqi1ba0t85whOBo4SElCSS6U1X7ckVbP2ROiNSOZjkfT6BGjZvtA+eN47gX0ckZzFyVoAeZbkfnCUjxejXQbeXaSC9odq3LZ/7B/HJDO3zLjzuY2ZeKzH79tBvEkyta7izr/U/kPSvxbEK5J540TbRfVLw2bCPwniPZK5SX2OCpiJw9P4vR/EuyTrcDyiyBVDHaZA+5kdt3HEBK8G+2NVYXjTBaLa8ektqeqLFGPQC1Jn1XNWqJEP+tguXg+7vkGa0mFDLgLOKOND9nA27fmImnbKbP4S+luDM/rRnU3Ckbq1prfG1D6TsbBFnyJ3GTViQ1NLtg+rzmLdOb6m1RX5NrrPDKSA/8ZZk58HyU0rto6QOGlL8QebPgO5zAJZKAAAAABJRU5ErkJggg=='), auto;
}

